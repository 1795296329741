import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

export default function AddressLink({
  color,
}) {
  return (
    <>
      <Button
        variant="text"
        startIcon={(
          <LocationOnOutlinedIcon
            style={{ color }}
          />
)}
        style={{ textTransform: 'none' }}
        href="http://maps.google.com/?q=P.O. Box 2657 Augusta, GA 30914"
        target="_blank"
      >
        <Typography
          variant="body2"
          style={{ color }}
        >
          P.O. Box 2657 Augusta, GA 30914
        </Typography>
      </Button>
    </>
  );
}

AddressLink.propTypes = {
  color: PropTypes.string,
};

AddressLink.defaultProps = {
  color: 'white',
};

/* eslint-disable react/prop-types */
import {
  Button,
  CardContent, Container, Grid, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { Wrapper } from '../components';
import EmailLink from '../components/EmailLink';
import PhoneLink from '../components/PhoneLink';
import AddressLink from '../components/AddressLink';
import SocialMediaLinks from '../components/SocialMediaLinks';
import useStyles from '../styles';

function Wrapped() {
  const classes = useStyles();
  const form = useRef();
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (name && number && email && message) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [name, number, email, message]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'default_service',
      'template_pwfg8bs',
      form.current,
      'user_K5lvL31ltJHB5VTbHk3cl',
    )
      .then(() => {
        setEmailSuccess(true);
      }, () => {
        setEmailSuccess(false);
      });
  };

  return (
    <>
      <Container className={classes.section}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item sm={7} xs={12}>
            {emailSuccess && (
              <>
                <Typography variant="subtitle2">
                  Thank you for your email.
                </Typography>
                <Typography variant="subtitle2">
                  A member of our staff will reach out to you shortly
                </Typography>
              </>
            )}
            {!emailSuccess && (
            <form ref={form} onSubmit={sendEmail}>
              <CardContent>
                <Typography variant="h4">
                  Write Us Your Query
                </Typography>
                <Typography variant="body2">
                  Have a query or want to discuss something? Feel free to contact us!
                </Typography>
                <br />
              </CardContent>
              <CardContent>
                <TextField
                  label="Full Name"
                  variant="outlined"
                  placeholder="Full Name"
                  fullWidth
                  focused
                  name="from_name"
                  required
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)}
                />
              </CardContent>
              <CardContent>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  placeholder="Email Address"
                  focused
                  name="from_email"
                  required
                  value={email || ''}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </CardContent>
              <CardContent>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  placeholder="Phone Number"
                  type="number"
                  fullWidth
                  focused
                  required
                  name="number"
                  value={number || ''}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </CardContent>
              <CardContent>
                <TextField
                  label="Message"
                  variant="outlined"
                  placeholder="Message"
                  multiline
                  rows={4}
                  fullWidth
                  focused
                  required
                  name="message"
                  value={message || ''}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </CardContent>
              <CardContent>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ borderRadius: 0 }}
                  size="large"
                  onClick={sendEmail}
                  disabled={!submit}
                >
                  <Typography variant="subtitle1">
                    Send Message
                  </Typography>
                </Button>
              </CardContent>
            </form>
            )}
          </Grid>
          <Grid item sm={4} xs={12}>
            <CardContent>
              <Typography variant="h4">
                Get In Touch
              </Typography>
            </CardContent>
            <CardContent>
              <div>
                <PhoneLink color="#000000" />
              </div>
              <div>
                <EmailLink color="#000000" />
              </div>
              <div>
                <AddressLink color="#000000" />
              </div>
            </CardContent>
            <CardContent>
              <Typography variant="h4">
                Social Links
              </Typography>
            </CardContent>
            <CardContent>
              <SocialMediaLinks />
            </CardContent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default function ContactPage() {
  return (
    <Wrapper title="Contact">
      <Wrapped />
    </Wrapper>
  );
}
